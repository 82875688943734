import {isUnknownArray} from '../../utils/is-unknown-array';
import {isUnknownEntry} from '../../utils/is-unknown-entry';
import {LiteralDeserializationError} from '../literal-deserialization-error';
import {LiteralPath} from '../literal-path';
import {ValueTransformer} from '../value-transformer';

// TODO specs
export class MapTransformer<K, V> extends ValueTransformer<Map<K, V>> {
  public constructor(
    private readonly _keyTransformer: ValueTransformer<K>,
    private readonly _valueTransformer: ValueTransformer<V>,
  ) {
    super();
  }

  public dataToLiteral(data: ReadonlyMap<K, V>): unknown {
    return Array.from<readonly [K, V], readonly [unknown, unknown]>(
      data,
      ([key, value]) => [
        this._keyTransformer.dataToLiteral(key),
        this._valueTransformer.dataToLiteral(value),
      ],
    );
  }

  public literalToData(literal: unknown, path: LiteralPath): Map<K, V> {
    if (!isUnknownArray(literal)) {
      throw new LiteralDeserializationError(literal, path);
    }

    const result = new Map<K, V>();

    for (let [index, field] of literal.entries()) {
      if (!isUnknownEntry(field)) {
        throw new LiteralDeserializationError(field, [path, index]);
      }

      result.set(
        this._keyTransformer.literalToData(field[0], [path, index, 0]),
        this._valueTransformer.literalToData(field[1], [path, index, 1]),
      );
    }

    return result;
  }

  public isSupport(data: unknown): data is Map<K, V> {
    if (!(data instanceof Map)) {
      return false;
    }

    for (const [key, value] of data) {
      if (
        !this._keyTransformer.isSupport(key) ||
        !this._valueTransformer.isSupport(value)
      ) {
        return false;
      }
    }

    return true;
  }
}
