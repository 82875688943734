import {asMap} from '@azarus/common/transformer/map/as-map';
import {MapTransformer} from '@azarus/common/transformer/map/map-transformer';
import {asString} from '@azarus/common/transformer/string/as-string';

class WindowNameFallbackStorage implements Storage {
  private readonly _transformer: MapTransformer<string, string> = asMap(
    asString(),
    asString(),
  );

  private readonly _map: Map<string, string>;

  public constructor() {
    try {
      this._map = this._transformer.literalToData(JSON.parse(window.name), []);
    } catch {
      this._map = new Map<string, string>();
    }
  }

  private _updateName(): void {
    window.name = JSON.stringify(this._transformer.dataToLiteral(this._map));
  }

  public get length(): number {
    return this._map.size;
  }

  public clear(): void {
    this._map.clear();
    this._updateName();
  }

  public getItem(key: unknown): string | null {
    return this._map.get(String(key)) ?? null;
  }

  public key(index: unknown): string | null {
    const normalizedIndex = Number(index);

    let keyIndex = 0;

    for (const key of this._map.keys()) {
      if (keyIndex === normalizedIndex) {
        return key;
      }
      keyIndex++;
    }

    return null;
  }

  public removeItem(key: unknown): void {
    this._map.delete(String(key));
    this._updateName();
  }

  public setItem(key: unknown, value: unknown): void {
    this._map.set(String(key), String(value));
    this._updateName();
  }
}

function isStorageSupported(storageHandler: () => Storage): boolean {
  const TEST_VALUE = '__STORE_TEST_VALUE__';

  try {
    const storage: Storage = storageHandler();

    storage.setItem(TEST_VALUE, TEST_VALUE);
    const matched: boolean = storage.getItem(TEST_VALUE) === TEST_VALUE;
    storage.removeItem(TEST_VALUE);

    return matched;
  } catch {
    return false;
  }
}

if (!isStorageSupported(() => sessionStorage)) {
  const value = new WindowNameFallbackStorage();

  Object.defineProperty(window, 'sessionStorage', {value});
}

if (!isStorageSupported(() => localStorage)) {
  Object.defineProperty(window, 'localStorage', {value: sessionStorage});
}

export {};
