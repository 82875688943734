import {ValueTransformer} from '../value-transformer';

import {MapTransformer} from './map-transformer';

export function asMap<K, V>(
  key: ValueTransformer<K>,
  value: ValueTransformer<V>,
): MapTransformer<K, V> {
  return new MapTransformer<K, V>(key, value);
}
